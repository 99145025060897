import { ready as DOMReady } from 'utils/DOMReady';

DOMReady(() => {
  if (!document.querySelector('.section-blog-main')) return;
  let showMore = document.querySelector('.show-more');
  const postPerPage = 3;
  const container = document.querySelector('.articles-wrapper');
  const filterBox = document.querySelector('.filter-box');
  const filters = [...document.querySelectorAll('.categories-select')].map(
    (filter) => {
      return {
        element: filter,
        category: filter.dataset.category,
      };
    }
  );

  const parameters = {
    category: [],
  };

  const setParameters = (category) => {
    if (!category) {
      parameters.category = [];
    } else {
      const index = parameters.category.indexOf(category);
      if (index > -1) parameters.category.splice(index, 1);
      else parameters.category.push(category);
    }
  };

  const navigate = () => {
    const urlParams = new URLSearchParams(window.location.search);

    if (parameters.category.length > 0) {
      urlParams.set('category', parameters.category.join(','));
    } else {
      urlParams.delete('category');
    }

    const urlString = urlParams.toString().replace(/%2C/g, ',');
    if (urlString)
      window.history.pushState(
        null,
        null,
        `${window.location.pathname}?${urlString}`
      );
    else window.history.pushState(null, null, window.location.pathname);
    window.dispatchEvent(new CustomEvent('locationchange'));
  };

  const getParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    parameters.category = urlParams.get('category')
      ? urlParams.get('category').split(',')
      : [];
  };

  const updateFilters = () => {
    filters.forEach((filter) => {
      if (!filter.category && !parameters.category[0]) {
        filter.element.classList.add('active');
      } else {
        if (parameters.category.indexOf(filter.category) > -1)
          filter.element.classList.add('active');
        else filter.element.classList.remove('active');
      }
    });
  };

  const filterAjax = (pKeyword) => {
    getParameters();
    updateFilters();

    const params = new URLSearchParams();
    const categories = parameters.category.join(',');
    params.set('action', 'filter_by_category');
    if (pKeyword) {
      params.set('s', pKeyword);
    }
    if (categories) params.set('category', categories);
    const paramsString = params.toString().replace(/%2C/g, ',');

    handleFetchingClass();

    fetch(`${wp.ajaxUrl}?${paramsString}`, {
      method: 'GET',
    })
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        if (showMore) showMore.remove();
        handleFetchingClass();
        container.innerHTML = html;
        showMore = container.querySelector('.show-more');
        if (showMore) {
          handleLoadMoreListener();
        }
      })
      .catch((error) => {
        handleFetchingClass();
      });
  };

  const handleFetchingClass = () => {
    [container, filterBox].forEach((element) => {
      element.classList.toggle('fetching');
    });
  };

  const handleLoadMoreListener = () => {
    showMore.addEventListener('click', function () {
      loadMore();
    });
  };

  const loadMore = () => {
    getParameters();

    const params = new URLSearchParams();
    const categories = parameters.category.join(',');
    params.set('action', 'filter_by_category');
    params.set('more', postPerPage);
    params.set('page', showMore.dataset.page);
    if (categories) params.set('category', categories);
    const paramsString = params.toString().replace(/%2C/g, ',');

    handleFetchingClass();

    fetch(`${wp.ajaxUrl}?${paramsString}`, {
      method: 'GET',
    })
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        if (showMore) showMore.remove();
        handleFetchingClass();

        container.innerHTML += html;
        showMore = container.querySelector('.show-more');
        if (showMore) {
          handleLoadMoreListener();
        }
      })
      .catch((error) => {
        handleFetchingClass();
      });
  };

  const handleSearchBar = () => {
    const searchForm = document.querySelector('.search-bar');
    const input = searchForm.querySelector('input');
    let timeOut = null;

    const search = () => {
      if (timeOut !== null) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(() => {
        filterAjax(input.value);
      }, 200);
    };

    searchForm.addEventListener('submit', (e) => {
      e.preventDefault();
      search();
    });

    input.addEventListener('input', function (e) {
      e.preventDefault();
      search();
    });
  };

  const init = () => {
    getParameters();

    updateFilters();

    filters.forEach((filter) => {
      filter.element.addEventListener('click', () => {
        setParameters(filter.category);
        navigate();
      });
    });

    window.addEventListener('locationchange', () => {
      filterAjax();
    });

    window.onpopstate = () => {
      filterAjax();
    };

    if (showMore) {
      handleLoadMoreListener();
    }

    handleSearchBar();
  };

  init();
});
