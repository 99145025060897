export function inViewPort(
  element,
  callback,
  options = { rootMargin: '50px', threshold: 0 },
  delay = 0
) {
  if (!element || !callback) return false;

  let inViewObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = entry.target;
        if (delay > 0) {
          setTimeout(() => {
            callback(target);
            inViewObserver.unobserve(target);
          }, delay);
          return;
        }
        callback(target);
        inViewObserver.unobserve(target);
      }
    });
  }, options);

  inViewObserver.observe(element);
}
