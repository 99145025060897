import { ready as DOMReady } from 'utils/DOMReady';
import Cookies from 'modules/Cookies';

DOMReady(() => {
  if (!document.querySelector('.blog-likes-btn')) return;
  const likesBtn = document.querySelector('.blog-likes-btn');
  const counter = document.querySelector('.likes-count');
  const cookies = new Cookies();

  const handleLikesRequest = () => {
    const currentLikes = parseInt(counter.innerHTML.trim());
    counter.innerHTML = currentLikes + 1;
    likesBtn.classList.add('liked');

    fetch(`${wp.ajaxUrl}?action=wp_like_article&id=${wp.post_id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          cookies.set(`likedCookie-${wp.post_id}`, true, 30);
        }
      })
      .catch((error) => {
        counter.innerHTML = currentLikes - 1;
        likesBtn.classList.remove('liked');
      });
  };

  const checkIfLiked = () => {
    if (cookies.get(`likedCookie-${wp.post_id}`)) {
      likesBtn.classList.add('liked');
    }
  };

  likesBtn.addEventListener('click', () => {
    handleLikesRequest();
  });

  checkIfLiked();
});
