import { ready as DOMReady } from 'utils/DOMReady';

DOMReady(() => {
  if (!document.querySelector('.booking-search-bar')) return;
  [...document.querySelectorAll('.booking-search-bar')].forEach(
    (bookingBar) => {
      const checkIn = bookingBar.querySelector('.check-in');
      const checkOut = bookingBar.querySelector('.check-out');
      const today = new Date();
      const twoDaysFromToday = new Date().setDate(new Date().getDate() + 2);
      const rangePicker = new window.litepicker({
        element: checkIn,
        parentEl: bookingBar,
        elementEnd: checkOut,
        firstDay: 1,
        format: 'D MMM, YYYY',
        lang: 'en-US',
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: today,
        maxDate: null,
        minDays: null,
        maxDays: null,
        selectForward: false,
        selectBackward: false,
        splitView: false,
        singleMode: false,
        autoApply: true,
        showWeekNumbers: false,
        showTooltip: true,
        disableWeekends: false,
        mobileFriendly: true,
        hotelMode: true,
        tooltipText: {
          one: 'night',
          other: 'nights',
        },
      });

      rangePicker.setDateRange(today, twoDaysFromToday);
    }
  );
});
