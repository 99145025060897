import { ready as DOMReady } from 'utils/DOMReady';
import Modal from 'modules/Modal';

DOMReady(() => {
  if (document.querySelector('.section-gallery-main')) {
    const modal = new Modal(document.querySelector('.modal'));
    const galleryImgs = document.querySelectorAll('.m-gallery-imgs');

    [...galleryImgs].forEach((image) => {
      image.addEventListener('click', function () {
        const img = modal.container.querySelector('.modal-img');
        const headline = modal.container.querySelector('.headline');

        img.setAttribute('src', this.dataset.image);
        img.setAttribute('alt', this.dataset.headline);

        headline.innerHTML = this.dataset.headline;
      });
    });
  }
});
