import { ready as DOMReady } from 'utils/DOMReady';

DOMReady(() => {
  if (!document.querySelector('.section-faqs-main')) return;
  const upArrowKeyCode = 38;
  const leftArrowKeyCode = 37;
  const downArrowKeyCode = 40;
  const faqsSection = document.querySelector('.section-faqs-main');
  const mobileFaqs = faqsSection.querySelectorAll('.faq.small');
  const faqCatBtns = document.querySelectorAll('.faq-cat-btn');
  const tabs = document.querySelectorAll('.tab-panel');
  const isTouchDevice = navigator.userAgent.match(
    /(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/
  );

  const handleMobile = (pTarget) => {
    const sign = pTarget.querySelector('.sign');
    const answer = pTarget.querySelector('.answer');
    sign.innerHTML = sign.innerHTML.trim() == '+' ? '-' : '+';
    answer.classList.toggle('open');
  };

  const handleArrows = (pActiveElement, pDirection) => {
    const currentIndex = parseInt(pActiveElement.dataset.index);
    const nextIndex =
      currentIndex !== faqCatBtns.length - 1 ? currentIndex + 1 : 0;
    const prevIndex =
      currentIndex !== 0 ? currentIndex - 1 : faqCatBtns.length - 1;

    if (pDirection == 'down') {
      faqCatBtns[nextIndex].focus();
    }

    if (pDirection == 'up') {
      faqCatBtns[prevIndex].focus();
    }
  };

  const handleDesktop = (pTarget) => {
    const target = pTarget.dataset.index;

    [...tabs, ...faqCatBtns].forEach((element) => {
      element.classList.remove('active');
    });

    [...faqCatBtns].forEach((element) => {
      element.setAttribute('aria-selected', 'false');
    });

    faqCatBtns[target].classList.toggle('active');
    faqCatBtns[target].setAttribute('aria-selected', 'true');

    faqCatBtns[target].focus();

    tabs[target].classList.add('active');
  };

  const handleAdaListeners = () => {
    window.addEventListener('keydown', (e) => {
      switch (e.keyCode) {
        case downArrowKeyCode:
          e.preventDefault();
          handleArrows(document.activeElement, 'down');
          break;
        case upArrowKeyCode:
          e.preventDefault();
          handleArrows(document.activeElement, 'up');
          break;
        case leftArrowKeyCode:
          const activeBtn = document.querySelector('.faq-cat-btn.active');
          activeBtn.focus();
          break;
      }
    });
  };

  [...mobileFaqs].forEach((faq) => {
    faq.addEventListener('click', () => {
      handleMobile(faq);
    });
  });

  [...faqCatBtns].forEach((faqBtn) => {
    faqBtn.addEventListener('click', () => {
      handleDesktop(faqBtn);
    });
  });

  if (!isTouchDevice) {
    handleAdaListeners();
  }
});
