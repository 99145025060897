export default class Modal {
  constructor(element) {
    this.container = element;
    this.dialog = this.container.querySelector('.modal-dialog');
    this.id = this.container.getAttribute('id');
    this.visible = this.container.classList.contains('visible');
    this.buttons = document.querySelectorAll(`[data-modal="${this.id}"]`);
    this.referrer = null;
    this.isOpen = false;
    this.tabContent = [];
    this.isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    this.init();
  }

  createBackdrop() {
    if (!document.querySelector('.modal-backdrop')) {
      const backdrop = document.createElement('div');
      backdrop.classList.add('modal-backdrop');
      backdrop.addEventListener('click', () => {
        this.close();
      });
      document.querySelector('body').appendChild(backdrop);
    }
  }

  destroyBackdrop() {
    if (document.querySelector('.modal-backdrop')) {
      const backdrop = document.querySelector('.modal-backdrop');
      backdrop.remove();
    }
  }

  open(referrerBtn) {
    this.referrer = referrerBtn;
    this.container.classList.add('visible');

    this.isOpen = true;

    this.tabContent = [];
    document
      .querySelectorAll('a, button, input, [tabindex="0"]')
      .forEach((btn) => {
        if (!this.container.contains(btn)) this.tabContent.push(btn);
      });

    setTimeout(() => {
      this.container.classList.add('slide');
    }, 25);

    setTimeout(() => {
      this.tabContent.forEach((element) => {
        element.setAttribute('tabindex', '-1');
      });
      this.dialog.focus();
      document.dispatchEvent(new CustomEvent('modal-open'));
    }, 400);

    if (this.container.dataset.hasBackdrop) {
      this.createBackdrop();
    }

    document.querySelector('body').style.overflow = 'hidden';
  }

  close() {
    this.container.classList.remove('slide');
    this.isOpen = false;

    setTimeout(() => {
      this.container.classList.remove('visible');
    }, 400);
    document.querySelector('body').removeAttribute('style');

    this.tabContent.forEach((element) => {
      element.removeAttribute('tabindex');
    });

    if (this.container.dataset.hasBackdrop) {
      this.destroyBackdrop();
    }

    document.dispatchEvent(new CustomEvent('modal-close'));

    this.referrer.focus();
  }

  init() {
    if (this.isIOS) this.dialog.classList.add('iOS');

    this.buttons.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        const method = btn.getAttribute('data-method')
          ? btn.getAttribute('data-method')
          : 'open';
        if (method === 'open') this.open(btn);
        else this.close();
      });
    });

    document.addEventListener('keydown', (pEvent) => {
      if (pEvent.keyCode == 27 && this.isOpen) {
        this.close();
      }
    });
  }
}
