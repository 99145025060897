import Glide, {
  Controls,
  Keyboard,
  Breakpoints,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';

if (document.querySelector('.glide.slider')) {
  [...document.querySelectorAll('.glide.slider')].forEach((element) => {
    const glide = new Glide(element, {
      type: 'slider',
      perView: 1,
      gap: 0,
    });

    glide.mount({ Controls, Keyboard, Breakpoints, Swipe });

    if (element.classList.contains('gallery')) {
      const images = element.querySelectorAll('.info-card-bg');
      [...images].forEach((image) => {
        image.addEventListener('click', function ({ target }) {
          const topic = target.parentNode.querySelector('.topic');
          const currentFirst = target.parentNode.querySelector(
            '[data-first="1"]'
          );

          this.classList.toggle('zoom');

          if (this.classList.contains('zoom')) {
            topic.classList.add('hide');
          } else {
            topic.classList.remove('hide');
          }

          if (this.dataset.last && this.classList.contains('zoom')) {
            currentFirst.classList.add('hide');
          }
          if (this.dataset.last && !this.classList.contains('zoom')) {
            currentFirst.classList.remove('hide');
          }
        });
      });
    }
  });
}
