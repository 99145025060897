import { ready as DOMReady } from 'utils/DOMReady';
import Cookies from 'modules/Cookies';

DOMReady(() => {
  if (!document.querySelector('.cookies-notifier')) return;
  const cookiesHandler = new Cookies();
  const notifier = document.querySelector('.cookies-notifier');
  const acceptBtn = notifier.querySelector('.accept-btn');
  const closeBtn = notifier.querySelector('.close-btn');

  acceptBtn.addEventListener('click', () => {
    notifier.classList.remove('d-block');
    cookiesHandler.set('cookiesAccepted', true, 2);
  });

  closeBtn.addEventListener('click', () => {
    notifier.classList.remove('d-block');
  });

  if (!cookiesHandler.get('cookiesAccepted')) {
    notifier.classList.add('d-block');
  }
});
