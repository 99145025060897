import { ready as DOMReady } from 'utils/DOMReady';

DOMReady(() => {
  if (document.querySelector('.single.availability-form.desktop')) {
    new window.litepicker({
      element: document.getElementById('datepicker'),
      parentEl: document.getElementById('availabilityForm'),
      elementEnd: document.getElementById('checkOut'),
      firstDay: 1,
      format: 'D MMM, YYYY',
      lang: 'en-US',
      numberOfMonths: 2,
      numberOfColumns: 2,
      minDate: new Date(),
      maxDate: null,
      minDays: null,
      maxDays: null,
      selectForward: false,
      selectBackward: false,
      splitView: false,
      inlineMode: true,
      singleMode: false,
      autoApply: true,
      showWeekNumbers: false,
      showTooltip: true,
      disableWeekends: false,
      mobileFriendly: true,
      hotelMode: true,
      tooltipText: {
        one: 'night',
        other: 'nights',
      },
    });
  }

  if (document.querySelector('.single.availability-form.mobile')) {
    new window.litepicker({
      element: document.getElementById('mobileDatepicker'),
      parentEl: document.getElementById('mobileAvailabilityForm'),
      elementEnd: document.getElementById('mobileCheckOut'),
      firstDay: 1,
      format: 'D MMM, YYYY',
      lang: 'en-US',
      numberOfMonths: 1,
      numberOfColumns: 1,
      minDate: new Date(),
      maxDate: null,
      minDays: null,
      maxDays: null,
      selectForward: false,
      selectBackward: false,
      splitView: false,
      inlineMode: true,
      singleMode: false,
      autoApply: true,
      showWeekNumbers: false,
      showTooltip: true,
      disableWeekends: false,
      mobileFriendly: true,
      hotelMode: true,
      tooltipText: {
        one: 'night',
        other: 'nights',
      },
    });
  }
});
