window.litepicker = require('litepicker');

import { ready as DOMReady } from 'utils/DOMReady';
import Parallax from 'modules/Parallax';
import LazyLoading from 'modules/LazyLoading';
import ContactForm from 'modules/ContactForm';

import 'modules/menu.js';
import 'modules/faqs.js';
import 'modules/single-accomodation.js';
import 'modules/booking-bar.js';
import 'modules/cookies-notifier.js';
import 'modules/blog-likes.js';
import 'modules/articles.js';
import 'modules/carousel.js';
import 'modules/slider.js';
import 'modules/mobile-gallery.js';

DOMReady(() => {
  window.lazyLoading = new LazyLoading();

  window.parallax = [...document.querySelectorAll('.parallax')].map((image) => {
    return new Parallax(image);
  });

  if (document.querySelector('.covid-message')) {
    const container = document.querySelector('.covid-message');
    container.querySelector('.close').addEventListener('click', () => {
      container.classList.add('d-none');
    });
  }

  if (document.querySelector('.contact-form')) {
    window.contactForm = new ContactForm(
      document.querySelector('.contact-form')
    );
  }
});
