import { debounce } from 'throttle-debounce';

export default class Parallax {
  constructor(container, options = {}) {
    const defaultOptions = {
      speed: 1.5,
    };
    this.container = container;
    this.options = Object.assign({}, defaultOptions, options);
    this.end = 0;
    this.height = 0;
    this.progress = 0;
    this.isAppleTouceDevice = this.isTouchDevice = navigator.userAgent.match(
      /(iPhone|iPod|iPad)/
    );
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.init();
  }

  setParallaxBoundaries() {
    const elementRect = this.container.getBoundingClientRect();
    this.end = elementRect.height * 2;
    this.height = elementRect.height;
  }

  scroll() {
    const elementRect = this.container.getBoundingClientRect();
    const top =
      -elementRect.top + this.height < 0 ? 0 : -elementRect.top + this.height;
    const progress = top > this.end ? this.end : top;

    this.progress = (progress * 100) / this.end;
  }

  init() {
    if (this.isAppleTouceDevice || this.isSafari) {
      this.container.classList.add('ios');
      return;
    }

    this.setParallaxBoundaries();
    this.scroll();

    const animationFrame = () => {
      this.container.style.backgroundPosition = `center ${
        this.progress * this.options.speed
      }%`;
    };

    window.requestAnimationFrame(animationFrame);

    window.addEventListener(
      'resize',
      debounce(250, () => {
        this.setParallaxBoundaries();
        this.scroll();
        window.requestAnimationFrame(animationFrame);
      })
    );

    window.addEventListener('scroll', () => {
      this.scroll();
      window.requestAnimationFrame(animationFrame);
    });
  }
}
