import Glide, {
  Controls,
  Keyboard,
  Breakpoints,
  Swipe,
  Autoplay,
} from '@glidejs/glide/dist/glide.modular.esm';

if (document.querySelector('.glide.carousel')) {
  const glide = new Glide('.glide.carousel', {
    type: 'carousel',
    focusAt: 'center',
    perView: 1,
    gap: 26,
    autoplay: 4000,
    peek: {
      before: 200,
      after: 200,
    },
    breakpoints: {
      600: {
        gap: 10,
        perView: 1,
        peek: {
          before: 50,
          after: 50,
        },
      },
    },
  });

  glide.mount({ Controls, Keyboard, Breakpoints, Swipe, Autoplay });
}
