import Component from 'utils/Component';

export default class Form extends Component {
  formSubmit() {}

  init() {
    this.fields = [...this.container.querySelectorAll('input, textarea')].map(
      (field) => {
        return {
          element: field,
          type: field.getAttribute('type'),
          name: field.name,
          value: field.value,
          isDirty: field.value ? true : false,
          validate: field.dataset.validate
            ? field.dataset.validate.split('|')
            : [],
          valid: true,
        };
      }
    );

    this.fields.forEach((field) => {
      this.input(field);

      field.element.addEventListener('input', (e) => {
        this.input(field);
      });
    });

    this.container.addEventListener('submit', (e) => {
      e.preventDefault();

      this.fields.forEach((field) => {
        this.validate(field);
      });

      if (this.isValid()) {
        this.formSubmit();
      } else {
      }
    });
  }

  input(field) {
    field.value = field.element.value;
    field.isDirty = field.value ? true : false;
    if (field.isDirty) field.element.parentNode.classList.add('is-dirty');
    else field.element.parentNode.classList.remove('is-dirty');
  }

  isValid() {
    let valid = true;
    this.fields.forEach((field) => {
      if (!field.valid) valid = false;
    });
    return valid;
  }

  validate(field) {
    field.validate.forEach((validation) => {
      if (validation === 'required') {
        field.valid = field.isDirty ? true : false;
      }
      if (validation === 'email') {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        field.valid = emailRegex.test(field.value);
      }
      if (!field.valid) {
        field.element.parentNode.classList.add('invalid');
      } else {
        field.element.parentNode.classList.remove('invalid');
      }
    });
  }
}
