import Form from 'modules/Form';

export default class ContactForm extends Form {
  formSubmit() {
    const submitBtn = this.container.querySelector('button');
    let formData = new FormData(this.container);
    formData.append('action', 'contact_form');

    const data = new URLSearchParams();
    for (const pair of formData) {
      data.append(pair[0], pair[1]);
    }

    submitBtn.classList.add('sending');
    submitBtn.setAttribute('disabled', true);

    fetch(`${wp.ajaxUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      body: data,
      credentials: 'same-origin',
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.success) {
          submitBtn.classList.remove('sending');
          submitBtn.classList.add('sent');
          submitBtn.innerHTML = 'Email Sent!';
        } else {
          submitBtn.classList.remove('sending');
          submitBtn.removeAttribute('disabled');
        }
      });
  }
}
