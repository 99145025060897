import { inViewPort } from 'utils/inViewport';

export default class LazyLoading {
  constructor(options) {
    const defaultOptions = {
      imageSelector: '.px-lazy-image',
      backgroundSelector: '.px-lazy-background',
    };
    this.options = Object.assign({}, defaultOptions, options);
    this.loaded = [];

    this.images = document.querySelectorAll(this.options.imageSelector);
    this.backgroundImages = document.querySelectorAll(
      this.options.backgroundSelector
    );

    this.init();
  }

  init() {
    this.backgroundImages.forEach((background) => {
      inViewPort(
        background,
        (element) => {
          this.loaded.push(element);
          const image = element.dataset.image;
          const placeholder = element.dataset.placeholder;
          element.style.backgroundImage = `url(${image}), url(${placeholder})`;
        },
        {
          rootMargin: '50px',
          threshold: 0.25,
        }
      );
    });

    this.images.forEach((image) => {
      inViewPort(
        image,
        (element) => {
          this.loaded.push(element);
          const image = element.dataset.image;
          element.setAttribute('src', image);
        },
        {
          rootMargin: '50px',
          threshold: 0.25,
        }
      );
    });
  }
}
