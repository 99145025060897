export default class Cookies {
  get(pName) {
    const cookie = document.cookie.match(`(^|;) ?${pName}=([^;]*)(;|$)`);
    return cookie ? cookie[2] : null;
  }

  set(pName, pValue, pDays) {
    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * pDays);
    document.cookie = `${pName}=${pValue};path=/;expires=${date.toGMTString()}`;
  }

  deleteCookie(pName) {
    setCookie(pName, '', -1);
  }
}
