export default class Component {
  constructor(container, options = {}) {
    this.container = container;
    this.options = options;
    this.body = document.querySelector('body');

    this.init();
  }

  init() {}
}
