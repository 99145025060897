import { ready as DOMReady } from 'utils/DOMReady';

DOMReady(() => {
  [
    ...document.querySelectorAll('input[name="polylang-check-language"]'),
  ].forEach((polylangElement) => {
    polylangElement.addEventListener('click', function () {
      window.location.href = this.dataset.url;
    });
  });

  [
    document.querySelector('.section-commons-header-menu-mobile-close'),
    document.querySelector('.section-commons-header-mobile-open'),
  ].forEach((element) => {
    element.addEventListener('click', () => {
      document
        .querySelector('.section-commons-header-menu-mobile')
        .classList.toggle('sticky');
    });
  });
});
